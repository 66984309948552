import { createApp } from "vue";
import "@/styles/index.less";
import svgIcon from "@/components/SvgIcon/index.vue";
import "vant/es/toast/style";
import "vant/es/notify/style";
import "vant/es/dialog/style";
import "vant/es/image-preview/style";
import "virtual:svg-icons-register";
import "amfe-flexible";
import App from "./App.vue";
import router from "./router";
import store from "@/store";
import RatioDirective from "@/directives/Ratio";

createApp(App)
  .use(router)
  .use(store)
  .component("svg-icon", svgIcon)
  .directive("ratio", RatioDirective)
  .mount("#app");
