<template>
  <van-config-provider :theme="theme">
    <RouterView></RouterView>
  </van-config-provider>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";
import useAppStore from "@/store/modules/app";
import useUserStore from "@/store/modules/user";
const appStore = useAppStore();
const userStore = useUserStore();
const { theme } = storeToRefs(appStore);
// 获取系统主题
appStore.updateTheme();

appStore.getConfig();
userStore.getUserInfo();
</script>
