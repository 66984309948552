// src/store/modules/appInfo.ts

import { defineStore } from "pinia";
import Api from "@/api/app";

export default defineStore("app", {
  state: () => {
    return {
      theme: <"light" | "dark" | undefined>undefined,
      appid: "",
      homeImg: "",
      set_agreement: "",
      open: false,
    };
  },
  getters: {},
  actions: {
    async getConfig() {
      const data = await Api.info();
      if (data.status === 0) {
        this.appid = data.data.wechat.appid;
        this.homeImg = data.data.homeImg;
        this.set_agreement = data.data.set_agreement;
        this.open = data.data.set_open === "1";
      }
      return true;
    },
    updateTheme() {
      const themeMedia = window.matchMedia("(prefers-color-scheme: light)");
      if (themeMedia.matches) {
        this.theme = "light";
      } else {
        this.theme = "dark";
      }
    },
  },
});
