// src/store/modules/user.ts

import { defineStore } from "pinia";
import uApi from "@/api/user";

export default defineStore("user", {
  state: () => {
    return {
      nickName: "",
      headurl: "",
    };
  },
  getters: {},
  actions: {
    async getUserInfo() {
      return new Promise((resolve) => {
        uApi
          .info()
          .then((res) => {
            if (res.status === 0) {
              this.nickName = res.data.name;
              this.headurl = res.data.headurl;
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
  },
});
