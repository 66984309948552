// 等比缩放

interface ZElement extends HTMLElement {
  _resizer: ResizeObserver;
}

export default {
  mounted(el: ZElement, binding: { value: "auto" }) {
    el.style.aspectRatio = binding.value;
  },
};
