<template>
  <svg aria-hidden="true" class="svg-icon" :style="style">
    <use :xlink:href="symbolId" :fill="props.color" />
  </svg>
</template>

<script setup>
import { computed } from "vue";
const props = defineProps({
  prefix: {
    type: String,
    default: "icon",
  },
  name: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    default: "currentColor",
  },
  size: {
    type: String,
    default: "1em",
  },
});

const symbolId = computed(() => `#${props.prefix}-${props.name}`);
const style = computed(() => ({ width: props.size, height: props.size }));
</script>
<style scoped>
.svg-icon {
  vertical-align: middle;
}
</style>
