// src/utils/http/axios.ts

import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from "axios";
import type { Response } from "./types";
// import { auth } from '@/utils';
import { showFailToast } from "vant";
import { getItem, removeItem } from "../storage";
import router from "@/router";

axios.defaults.timeout = 1000 * 60;
axios.defaults.headers.post["Content-Type"] = "application/json; charset=utf-8";

// 创建axios实例
const service = axios.create({
  // 根据不同env设置不同的baseURL
  baseURL: import.meta.env.VITE_APP_API_BASE_URL,
});

// axios实例拦截请求
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.headers = {
      ...config.headers,
      Authorization: getItem("token"),
      // ...auth.headers(), // 你的自定义headers，如token等
    };
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

// axios实例拦截响应
service.interceptors.response.use(
  // 2xx时触发
  (response: AxiosResponse<Response>) => {
    // response.data就是后端返回的数据，结构根据你们的约定来定义
    // console.log(response.data);
    // return;
    const { status, msg } = response.data;
    let errMessage = "";
    switch (status) {
      case 2: // token过期
        errMessage = msg;
        router.push({
          name: "NotAuth",
        });
        break;
      default:
        break;
    }
    if (errMessage) showFailToast(errMessage);
    return response;
  },
  // 非2xx时触发
  (error: AxiosError<Response>) => {
    const { status, data } = error.response;
    showFailToast(data.msg || "Network Error...");
    if (status === 401) {
      removeItem("token");
      router.push({
        name: "Login",
        query: { redirect: encodeURIComponent(window.location.pathname) },
      });
    }
    // return Promise.reject(error);
  }
);

export type { AxiosResponse, AxiosRequestConfig };

export default service;
