// src/utils/http/index.ts

import service, { AxiosRequestConfig } from "./axios";
export * from "./types";

export const request = <T = unknown>(
  config: AxiosRequestConfig
): Promise<T> => {
  return new Promise((resolve) => {
    service
      .request(config)
      .then((res) => {
        // 一些业务处理
        resolve(res.data);
      })
      .catch((err) => {
        console.log("request fail:", err);
      });
  });
};

const http = {
  get<T = unknown>(
    url: string,
    params = {},
    config?: AxiosRequestConfig
  ): Promise<T> {
    return request({ url, params, ...config, method: "GET" });
  },
  post<T = unknown>(
    url: string,
    data = {},
    config?: AxiosRequestConfig
  ): Promise<T> {
    return request({ url, data, ...config, method: "POST" });
  },
  put<T = unknown>(
    url: string,
    data = {},
    config?: AxiosRequestConfig
  ): Promise<T> {
    return request({ url, data, ...config, method: "PUT" });
  },
  delete<T = unknown>(
    url: string,
    data = {},
    config?: AxiosRequestConfig
  ): Promise<T> {
    return request({ url, data, ...config, method: "DELETE" });
  },
  // 上传文件，指定 'Content-Type': 'multipart/form-data'
  upload<T = unknown>(
    url: string,
    data = {},
    config?: AxiosRequestConfig
  ): Promise<T> {
    return request({
      url,
      data,
      ...config,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
};

export default http;
