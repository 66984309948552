import { createRouter, createWebHistory } from "vue-router";
import { getItem } from "@/utils/storage";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/home/index.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/content/:type/:id",
    name: "Content",
    component: () => import("@/views/content/index.vue"),
  },
  {
    path: "/goods/:code",
    name: "Goods",
    component: () => import("@/views/goods/index.vue"),
  },
  {
    path: "/order/create/:code",
    name: "OrderCreate",
    component: () => import("@/views/order/create/index.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/order/list",
    name: "OrderList",
    component: () => import("@/views/order/list/index.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/order/detail/:orderNo",
    name: "OrderDetail",
    component: () => import("@/views/order/detail/index.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pay/:orderNo",
    name: "Pay",
    component: () => import("@/views/pay/index.vue"),
    meta: {
      requiresAuth: true,
      title: "收银台",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/index.vue"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/404",
    name: "NotPage",
    component: () => import("@/views/notPage/index.vue"),
    meta: {
      title: "404 Page NotFund",
    },
  },
  // 所有未定义路由，全部重定向到404页
  {
    path: "/:catchAll(.*)",
    redirect: "/404",
  },
];

// app router
export const router = createRouter({
  history: createWebHistory("/"),
  routes: <never[]>routes,
  strict: true,
  scrollBehavior: () => ({ left: 0, top: 0 }),
});

router.beforeEach(async (to) => {
  if (to.meta.title) {
    document.title = <string>to.meta.title;
  }
  // 校验登录状态
  if (to.meta.requiresAuth && !getItem("token")) {
    console.log(to.fullPath);
    return {
      name: "Login",
      query: { redirect: encodeURIComponent(to.fullPath) },
    };
  }
  if (to.name === "Login" && getItem("token")) {
    const path = to.query.redirect
      ? decodeURIComponent(<string>to.query.redirect)
      : "/";
    return { path };
  }
  // 开始加载进度条
});

export default router;
