import http, { Response } from "@/utils/http";

export interface AppConfig {
  homeImg: string;
  set_agreement: string;
  set_open: string;
  web_url: string;
  wechat: { appid: string };
}

export default {
  async info() {
    return await http.get<Response<AppConfig>>("config");
  },
};
