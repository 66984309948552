import http, { Response } from "@/utils/http";

export interface UserInfo {
  headurl: string;
  name: string;
}

export default {
  async info() {
    return await http.get<Response<UserInfo>>("userinfo");
  },
};
